<template>
  <textarea
    class="form-control field"
    type="text"
    name="ans"
    rows="4"
    placeholder="Answer"
    v-model="ans"
  ></textarea>
</template>

<script>
import api from "@/api";

export default {
  name: "answer-essay",
  data() {
    const ans = api.load_answer_local(this.question);
    return {
      ans: ans ? ans : "",
    };
  },
  props: {
    question: Object,
  },
  methods: {
    get_answer(force) {
      if (!force && this.ans == "" && !confirm("Submit empty answer?"))
        return null;
      return this.ans;
    },
  },
  watch: {
    ans() {
      api.save_answer_local(this.question, this.ans);
    },
  },
};
</script>

<style>
.answer-form[data-type="essay"] {
  padding: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.answer-form[data-type="essay"] textarea {
  flex: 1 0 auto;
}
</style>
