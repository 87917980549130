var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[(_vm.node.children)?_c('div',{staticClass:"tree-item-label",class:{
      clickable: _vm.clickable,
      selected: _vm.node.entry == _vm.$route.params.entry,
      disabled: !_vm.available || _vm.stats.available == 0,
    },on:{"click":function($event){return _vm.toggle_node_expand(_vm.node)}}},[_c('span',[_vm._v(_vm._s(!_vm.expand ? "+" : "-"))]),(!_vm.expand)?_c('div',{staticClass:"right"},[_c('div',{staticClass:"marker",class:_vm.group_marker_class})]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]):(_vm.clickable)?_c('router-link',{staticClass:"tree-item-label",class:{
      clickable: true,
      selected: _vm.node.entry == _vm.$route.params.entry,
      disabled: !_vm.available,
    },attrs:{"to":{
      name: 'exam_node',
      params: { entry: _vm.node.entry },
    },"replace":""},nativeOn:{"click":function($event){return _vm.show_instruction(null)}}},[_c('span',[_vm._v("•")]),_vm._v(" "+_vm._s(_vm.title)+" "),_c('div',{staticClass:"right"},[_c('div',{staticClass:"marker",class:_vm.marker_class})])]):_c('div',{staticClass:"tree-item-label",class:{
      clickable: false,
      selected: _vm.node.entry == _vm.$route.params.entry,
      disabled: !_vm.available,
    },attrs:{"to":{
      name: 'exam_node',
      params: { entry: _vm.node.entry },
    },"event":_vm.clickable ? 'click' : ''}},[_c('span',[_vm._v("•")]),_vm._v(" "+_vm._s(_vm.title)+" "),_c('div',{staticClass:"right"},[_c('div',{staticClass:"marker",class:_vm.marker_class})])]),(_vm.node.children && _vm.expand)?_c('ul',{staticClass:"tree-list"},[(_vm.node.opt.instruction)?_c('li',[_c('div',{staticClass:"tree-item-label instruction",class:{
          clickable: true,
          selected: _vm.instruction && _vm.node.entry == _vm.instruction.entry,
          disabled: !true,
        },on:{"click":function($event){return _vm.click_instruction()}}},[_c('span',[_vm._v("◦")]),_vm._v(" "+_vm._s(_vm.node.opt.instruction_title || "Instruction")+" ")])]):_vm._e(),_vm._l((_vm.node.children),function(child){return _c('sidebar-tree-item',{key:child.index,attrs:{"node":child,"lock":_vm.lock}})})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }