import axios from "axios";
import router from "@/router";
import store from "@/store";

const axios_inst = axios.create({
  timeout: 5000,
});

async function handle_error(error) {
  const resp = error.response;
  if (resp && resp.status == 401) {
    router.push({ name: "login" });
    throw error;
  } else {
    api.report_error(
      JSON.stringify(error) + (resp ? ";[RESP]" + JSON.stringify(resp) : "")
    );
    console.log(error, resp);

    await new Promise((r) => setTimeout(r, 500)); // Sleep
    alert("Problem encountered. Please refresh this page [F5].");
    window.location.reload();
  }
}
function handle_user_error(error) {
  if (!error) error = "Unknown error";
  alert(`Error: ${error}. Please refresh this page [F5].`);
  window.location.reload();
}
function next_slibling(node, path) {
  if (path.length < 1) return null;
  const parent = path[path.length - 1];
  if (node.index + 1 < parent.children.length)
    return parent.children[node.index + 1];
  return null;
}
function _node_ok_available(node) {
  if (node.type == "group") return api.node_free_skip(node);
  if (!api.node_available(node)) return false;
  return true;
}
function _node_ok_skip_submitted(node) {
  if (node.type == "group") return api.node_free_skip(node);
  if (!api.node_available(node)) return false;
  if (
    store.state.timeinfo[node.entry] &&
    store.state.timeinfo[node.entry].submit !== null
  )
    return false;
  return true;
}
function _get_next_question(entry, node_ok, down) {
  let { node, path } = api.get_node(entry);
  if (!down)
    for (const e of path) {
      if (node_ok(e)) return e;
    }
  // move down
  while (node.children) {
    path.push(node);
    node = node.children[0];
    if (node_ok(node, path)) return node;
  }
  while (node !== null) {
    // move horizontal + up
    node = next_slibling(node, path);
    while (node === null && path.length > 1) {
      node = path.pop();
      node = next_slibling(node, path);
    }
    if (node === null) break; // no more
    if (node_ok(node, path)) break;
    // move down
    while (node.children) {
      path.push(node);
      node = node.children[0];
    }
    if (node_ok(node, path)) break;
  }
  return node;
}
function _traverse(node, callbacks) {
  if (callbacks.all) callbacks.all(node);
  if (node.type == "group") {
    if (callbacks.group) callbacks.group(node);
    for (const c of node.children) {
      _traverse(c, callbacks);
    }
    if (callbacks.group_post) callbacks.group_post(node);
  } else {
    if (callbacks.question) callbacks.question(node);
  }
}
function _combine_timerange(oldr, newr) {
  if (oldr[0] < newr[0]) oldr[0] = newr[0];
  if (oldr[1] > newr[1]) oldr[1] = newr[1];
  return oldr;
}
function _propagate_time(e, parentr) {
  const tinfo = store.state.timeinfo[e.entry];
  e.timerange = [-Infinity, Infinity];
  if (e.type == "group") {
    if (e.opt.starttime) e.timerange[0] = e.opt.starttime;
    else if (tinfo && tinfo.start) e.timerange[0] = tinfo.start;
    if (e.timerange[0] != -Infinity && e.opt.timelimit)
      e.timerange[1] = e.timerange[0] + e.opt.timelimit;
    _combine_timerange(e.timerange, parentr);
    for (const c of e.children) {
      _propagate_time(c, e.timerange);
    }
  } else {
    if (e.starttime) e.timerange[0] = e.starttime;
    else if (tinfo && tinfo.start) e.timerange[0] = tinfo.start;
    if (e.timerange[0] != -Infinity && e.timelimit)
      e.timerange[1] = e.timerange[0] + e.timelimit;
    _combine_timerange(e.timerange, parentr);
  }
}

const local_storage = window.localStorage;

const api = {
  save_answer_local(question, answer) {
    const time = (Date.now() + store.state.timeoffset) / 1000;
    if (local_storage) {
      local_storage.setItem(question.entry, JSON.stringify({ time, answer }));
    }
  },
  load_answer_local(question) {
    const tinfo = store.state.timeinfo[question.entry];
    let anstime = null;
    let ans = null;
    if (tinfo && tinfo.submit !== null) {
      anstime = tinfo.submit;
      ans = question.answer;
    }
    let local = local_storage && local_storage.getItem(question.entry);
    if (local) {
      local = JSON.parse(local);
      if (anstime === null || local.time > anstime) {
        anstime = local.time;
        ans = local.answer;
      }
    }
    return ans;
  },

  async report_error(msg) {
    try {
      await axios.post("/api/report_error", { msg });
    } catch {
      // pass
    }
  },
  async logout() {
    try {
      await axios_inst.post("/api/logout");
    } catch {
      // pass
    } finally {
      router.push({ name: "login" });
    }
  },
  async login(token) {
    store.commit("exam_reset");
    const resp = await axios_inst.post("/api/login", { token: token });
    if (resp.data.success) {
      store.commit("update_user_info", resp.data);
      router.push({ name: "exam" });
    } else throw new Error(resp.data.error);
  },

  async sync_time() {
    try {
      let time = Date.now();
      const response = await axios_inst.get("/api/time");
      time = Date.now() - time;
      const data = response.data;
      const offset = data.now * 1000 - Date.now();
      axios_inst.post("/api/rtt/time", {
        rtt: time,
        offset: Math.round(offset),
      });
      store.commit("set_timeoffset", offset);
    } catch {
      //pass
    }
  },
  async load_tree() {
    if (!store.state.tree.init) return;
    try {
      let time = Date.now();
      const response = await axios_inst.get("/api/exam_info");
      time = Date.now() - time;
      const data = response.data;
      const offset = data.now * 1000 - Date.now();
      try {
        axios_inst.post("/api/rtt/exam_info", {
          rtt: time,
          offset: Math.round(offset),
        });
      } catch {
        //pass
      }
      store.commit("set_timeoffset", offset);
      store.commit("update_timeinfo", data.timeinfo);
      store.commit("update_tree", data.exam);

      // randomly sync time
      setTimeout(() => {
        api.sync_time();
      }, Math.floor(Math.random() * 2000 + 1000));
    } catch (error) {
      return await handle_error(error);
    }
  },
  async load_user_info(force, pass_error) {
    if (!force && store.state.user_info.success) return;
    try {
      const response = await axios_inst.get("/api/user_info");
      if (response.data.success)
        store.commit("update_user_info", response.data);
    } catch (error) {
      if (!pass_error) return await handle_error(error);
    }
  },

  async load_question(entry) {
    if (entry == "") return;
    if (entry in store.state.questions && !store.state.questions[entry].preload)
      return;
    try {
      const response = await axios_inst.get("/api/question/" + entry);
      if (response.data.error) return handle_user_error(response.data.error);
      const question = response.data;
      question.preload = false;
      store.commit("update_questions", question);
    } catch (error) {
      return await handle_error(error);
    }
  },
  async preload_question(entry) {
    if (entry == "") return;
    if (entry in store.state.questions) return;
    try {
      const response = await axios_inst.get(`/api/question/${entry}/preload`);
      if (response.data.error) return handle_user_error(response.data.error);
      const question = response.data;
      if (question.images) {
        const srcs = question.images.map((i) => "/api/res/" + i);
        for (const i of srcs) {
          let img = new Image();
          img.src = i;
        }
      }
      question.preload = true;
      store.commit("update_questions", question);
    } catch (error) {
      return await handle_error(error);
    }
  },

  async start_question(question) {
    const tinfo = store.state.timeinfo[question.entry];
    if (tinfo && tinfo.start !== null) return;
    try {
      const resp = await axios_inst.post(
        `/api/question/${question.entry}/start`
      );
      if (resp.data.success) {
        if (resp.data.timeinfo)
          store.commit("update_timeinfo", resp.data.timeinfo);
      } else return handle_user_error(resp.data ? resp.data.error : null);
    } catch (error) {
      return await handle_error(error);
    }
  },
  async send_answer(question, answer) {
    try {
      const resp = await axios_inst.post(
        `/api/question/${question.entry}/submit`,
        { answer: answer }
      );
      if (resp.data.success) {
        store.commit("update_answer", {
          entry: question.entry,
          answer: resp.data.answer,
        });
        store.commit("update_timeinfo", resp.data.timeinfo);
      } else return handle_user_error(resp.data ? resp.data.error : null);
    } catch (error) {
      // Retry just-in-case
      try {
        axios.post(`/api/question/${question.entry}/submit`, {
          answer: answer,
        });
      } catch {
        //pass
      }
      return await handle_error(error);
    }
  },
  async finish_group(node) {
    try {
      const resp = await axios_inst.post(`/api/group/${node.entry}/submit`);
      if (resp.data.success) {
        store.commit("update_timeinfo", resp.data.timeinfo);
      } else return handle_user_error(resp.data ? resp.data.error : null);
    } catch (error) {
      return await handle_error(error);
    }
  },

  // Utils
  format_duration(secs) {
    secs = Math.ceil(secs);
    if (secs < 0) secs = 0;
    let min = Math.floor(secs / 60);
    secs %= 60;
    let hrs = Math.floor(min / 60);
    min %= 60;
    hrs = hrs ? hrs + ":" : "";
    min = min < 10 ? "0" + min : min.toString();
    secs = secs < 10 ? "0" + secs : secs.toString();
    return hrs + min + ":" + secs;
  },

  // UI API
  get_node(entry) {
    try {
      return {
        node: store.state.tree.entry_map[entry],
        path: store.state.tree.node_path[entry].slice(),
      };
    } catch {
      return { node: null, path: [] };
    }
  },
  get_nodei(entryi) {
    try {
      let node = store.state.tree.entryi_map[entryi];
      return { node, path: store.state.tree.node_path[node.entry].slice() };
    } catch {
      return { node: null, path: [] };
    }
  },
  get_next_node(entry, down) {
    let root_entry = store.state.tree.entry;
    let output = null;
    if (down) {
      const { node } = api.get_node(entry);
      if (_node_ok_skip_submitted(node)) return node;
    }
    if (output === null)
      output = _get_next_question(entry, _node_ok_skip_submitted, down);
    if (output === null)
      output = _get_next_question(root_entry, _node_ok_skip_submitted, down);
    if (output === null)
      output = _get_next_question(entry, _node_ok_available, down);
    if (output === null)
      output = _get_next_question(root_entry, _node_ok_available, down);
    return output;
  },
  get_started_node() {
    let output = null;
    _traverse(store.state.tree, {
      question(e) {
        if (output) return;
        if (!api.node_available(e)) return;
        const tinfo = store.state.timeinfo[e.entry];
        if (tinfo && tinfo.start !== null && tinfo.submit === null) output = e;
      },
    });
    return output;
  },
  get_next_pending_node() {
    let output = null;
    _traverse(store.state.tree, {
      all(e) {
        if (!api.node_started(e)) if (output === null) output = e;
      },
    });
    return output;
  },
  node_available(node) {
    const tinfo = store.state.timeinfo[node.entry];
    if (!api.node_intime(node)) {
      return false;
    }
    if (node.type != "group") {
      // questions
      if (tinfo && node.singlesubmit && tinfo.submit !== null) {
        return false;
      }
      if (!node.singlecheck) return false;
      if (!node.skipcheck) {
        if (tinfo && (tinfo.start !== null || tinfo.submit !== null)) {
          return true;
        }
        return false;
      }
    } else {
      if (tinfo && node.opt.singlesubmit && tinfo.submit !== null) {
        // redundant
        return false;
      }
      return node.singlecheck && node.skipcheck;
    }
    return true;
  },
  node_intime(node) {
    const time = store.state.tree_time;
    if (
      node.timerange &&
      (node.timerange[0] > time || time >= node.timerange[1])
    )
      return false;
    return true;
  },
  node_started(node) {
    const time = store.state.tree_time;
    if (node.type == "group") {
      if (node.opt.starttime) return node.opt.starttime <= time;
    } else if (node.starttime) return node.starttime <= time;
    return true;
  },
  node_free_skip(node) {
    if (
      node.type == "group" &&
      node.opt.free &&
      (node.opt["skip-questions"] != -1 || node.opt["skip-child"] != -1)
    ) {
      return node._free_skip;
    } else return false;
  },
  count_question(node, intime) {
    const output = {
      count: 0,
      submitted: 0,
      started: 0,
      available: 0,
    };
    _traverse(node, {
      question(e) {
        if (intime && !api.node_intime(e)) return;
        output.count++;
        if (api.node_available(e)) output.available++;
        if (store.state.timeinfo[e.entry]) {
          output.started++;
          if (store.state.timeinfo[e.entry].submit !== null) output.submitted++;
        }
      },
    });
    return output;
  },
  node_has_pending_submit(node) {
    const { path } = api.get_node(node.entry);
    path.push(node);
    path.reverse();
    for (const e of path) {
      if (e._pending_submit) return e;
    }
    return null;
  },

  group_title(node, prefer_short, params) {
    let title = node.opt.title;
    if (prefer_short && node.opt.short) title = node.opt.short;
    if (!title) return title;
    for (const param in params)
      title = title.replace("%" + param + "%", params[param]);
    return title.trim();
  },

  // Hooks
  time_tick(force_recalc) {
    const tree = store.state.tree;
    const time = (Date.now() + store.state.timeoffset) / 1000;
    if (time >= tree.nextevent || force_recalc) {
      // recalculate skipcheck
      store.state.tree_time = time;
      _traverse(tree, {
        all(node) {
          node.skipcheck = true;
          node.singlecheck = true;
        },
        question(node) {
          if (!api.node_intime(node)) {
            node._started = node._finished = api.node_started(node);
            return;
          }
          const tinfo = store.state.timeinfo[node.entry];
          node._started = tinfo && tinfo.start !== null;
          node._finished = tinfo && tinfo.submit !== null;
        },
        group_post(node) {
          if (!api.node_intime(node)) {
            node._started = node._finished = api.node_started(node);
            return;
          }
          if (node.children) {
            let started = false;
            let finished = true;
            for (const c of node.children) {
              started |= c._started;
              finished &= c._finished;
            }
            node._started = started;
            node._finished = finished;

            const tinfo = store.state.timeinfo[node.entry];
            if (node.opt.singlesubmit) {
              node._finished = tinfo && tinfo.submit !== null;
              if (finished && !node._finished) {
                node._pending_submit = true;
              } else {
                node._pending_submit = false;
              }
              if (node._finished) {
                // propagate down
                _traverse(node, {
                  all(c) {
                    c.singlecheck = false;
                  },
                });
              }
            }
          } else {
            node._started = true;
            node._finished = true;
          }
        },
      });
      _traverse(tree, {
        group(node) {
          if (!node.skipcheck) return;
          if (node.opt["skip-questions"] != -1 && node.children.length) {
            let skip_allow = node.opt["skip-questions"] + 1;
            let skip_seen = [];
            let skip_unseen = [];
            if (node.opt.free) {
              // free skip
              _traverse(node, {
                question(e) {
                  if (!api.node_intime(e)) return;
                  if (e._finished) return;
                  if (e._started && skip_allow > 0) {
                    skip_allow--;
                    skip_seen.push(e);
                  } else skip_unseen.push(e);
                  e.skipcheck = true;
                },
              });

              node._free_skip = true;
              if (skip_allow <= 0 || skip_unseen.length == 0) {
                node._free_skip = false;
              }
              if (skip_allow <= 0) {
                // disable unseen
                for (const e of skip_unseen) e.skipcheck = false;
              }
            } else {
              // sequential skip
              _traverse(node, {
                question(e) {
                  if (!api.node_intime(e)) return;
                  if (e._finished) return;
                  if (skip_allow > 0) {
                    skip_allow--;
                    if (e._started) skip_seen.push(e);
                    else skip_unseen.push(e);
                    e.skipcheck = true;
                  } else {
                    e.skip = 0;
                    e.skipcheck = false;
                  }
                },
              });
            }
            if (node.opt["skip-questions"] != 0) {
              let skip_left = node.opt["skip-questions"] + 1 - skip_seen.length;
              if (skip_left <= 0) skip_left = -2;
              for (const e of skip_seen) e.skip = skip_left;
              skip_left--;
              if (skip_left <= 0) skip_left = -2;
              for (const e of skip_unseen) e.skip = skip_left;
            } else {
              for (const e of skip_seen) e.skip = 0;
              for (const e of skip_unseen) e.skip = 0;
            }
          }
          if (node.opt["skip-child"] != -1 && node.children.length) {
            // use with group only
            let skip_allow = node.opt["skip-child"] + 1;
            if (node.opt.free) {
              // free skip
              node._free_skip = true;
              let unseen = [];
              for (const c of node.children) {
                if (!api.node_intime(c)) continue;
                if (c._finished) continue;
                if (c._started && skip_allow > 0) {
                  skip_allow--;
                } else unseen.push(c);
                c.skipcheck = true;
              }
              if (skip_allow <= 0 || unseen.length == 0) {
                node._free_skip = false;
              }
              if (skip_allow <= 0) {
                for (const c of unseen) {
                  _traverse(c, {
                    all(e) {
                      e.skipcheck = false;
                    },
                  });
                }
              }
            } else {
              // sequential skip
              for (const c of node.children) {
                if (!api.node_intime(c)) continue;
                if (c._finished) continue;
                if (skip_allow > 0) {
                  skip_allow--;
                  c.skipcheck = true;
                } else {
                  c.skipcheck = false;
                  _traverse(c, {
                    all(e) {
                      e.skipcheck = false;
                    },
                  });
                }
              }
            }
          }
        },
      });

      let nextevt = Infinity;
      _traverse(tree, {
        all(node) {
          // recalculate next event
          if (!node.timerange) return;
          if (node.timerange[0] > time && nextevt > node.timerange[0])
            nextevt = node.timerange[0];
          if (node.timerange[1] > time && nextevt > node.timerange[1])
            nextevt = node.timerange[1];
        },
      });
      tree.nextevent = nextevt;
      store.commit("update_tree_time", time);
    }
  },
  hook_build_tree(tree) {
    tree.entryi = "r";
    tree.entry_map = {};
    tree.node_path = {};
    tree.entryi_map = {};
    const traverse = (e, path) => {
      tree.entry_map[e.entry] = e;
      tree.node_path[e.entry] = path;
      tree.entryi_map[e.entryi] = e;

      e.timerange = [-Infinity, Infinity];
      e.skipcheck = true;
      e.singlecheck = true;
      e._finished = false;
      e._started = false;
      if (e.type == "group") {
        // defaults
        if (e.opt.free === undefined) e.opt.free = false;
        if (e.opt.singlesubmit === undefined) e.opt.singlesubmit = false;
        if (e.opt["skip-child"] === undefined) e.opt["skip-child"] = -1;
        if (e.opt["skip-questions"] === undefined) e.opt["skip-questions"] = -1;
        if (e.opt.starttime === undefined) e.opt.starttime = 0;
        if (e.opt.timelimit === undefined) e.opt.timelimit = 0;

        e.expand = 0;
        if (e.opt.singlesubmit) e._pending_submit = false;
        for (const [k, c] of e.children.entries()) {
          c.index = k;
          c.entryi = e.entryi + "." + k;
          traverse(c, [...path, e]);
        }
      } else {
        // defaults
        if (e.singlesubmit === undefined) e.singlesubmit = false;
        if (e.starttime === undefined) e.starttime = 0;
        if (e.timelimit === undefined) e.timelimit = 0;
        if (e.early === undefined) e.early = true;
        e.skip = -1;
      }
    };
    traverse(tree, []);
    store.state.tree = tree;
    _propagate_time(tree, [-Infinity, Infinity]);
    api.time_tick(true);
    return tree;
  },
  hook_timeinfo_after(timeinfos) {
    for (const entry in timeinfos) {
      const { node, path } = api.get_node(entry);
      if (node)
        _propagate_time(
          node,
          path.length ? path.pop().timerange : [-Infinity, Infinity]
        );
    }

    // Recheck
    let max_start = 0;
    for (const entry in store.state.timeinfo) {
      const tinfo = store.state.timeinfo[entry];
      if (tinfo.start > max_start) max_start = tinfo.start;
    }

    const new_offset = max_start * 1000 - Date.now();
    if (max_start != 0 && new_offset > store.state.timeoffset) {
      const old_offset = store.state.timeoffset;
      store.commit("set_timeoffset", new_offset);
      api.report_error(
        `Late start time. Resync ${Math.round(old_offset)} -> ${new_offset} ms`
      );

      // api.sync_time();
    }

    api.time_tick(true);
  },
};
export default api;
