<template>
  <li>
    <div
      v-if="node.children"
      class="tree-item-label"
      :class="{
        clickable: clickable,
        selected: node.entry == $route.params.entry,
        disabled: !available || stats.available == 0,
      }"
      @click="toggle_node_expand(node)"
    >
      <span>{{ !expand ? "+" : "-" }}</span>
      <div class="right" v-if="!expand">
        <div class="marker" :class="group_marker_class"></div>
      </div>
      {{ title }}
    </div>

    <router-link
      v-else-if="clickable"
      :to="{
        name: 'exam_node',
        params: { entry: node.entry },
      }"
      class="tree-item-label"
      :class="{
        clickable: true,
        selected: node.entry == $route.params.entry,
        disabled: !available,
      }"
      @click.native="show_instruction(null)"
      replace
    >
      <span>&bull;</span> {{ title }}
      <div class="right">
        <div class="marker" :class="marker_class"></div></div
    ></router-link>
    <div
      v-else
      :to="{
        name: 'exam_node',
        params: { entry: node.entry },
      }"
      :event="clickable ? 'click' : ''"
      class="tree-item-label"
      :class="{
        clickable: false,
        selected: node.entry == $route.params.entry,
        disabled: !available,
      }"
    >
      <span>&bull;</span> {{ title }}
      <div class="right">
        <div class="marker" :class="marker_class"></div>
      </div>
    </div>

    <ul v-if="node.children && expand" class="tree-list">
      <li v-if="node.opt.instruction">
        <div
          class="tree-item-label instruction"
          :class="{
            clickable: true,
            selected: instruction && node.entry == instruction.entry,
            disabled: !true,
          }"
          @click="click_instruction()"
        >
          <span>&#9702;</span> {{ node.opt.instruction_title || "Instruction" }}
        </div>
      </li>
      <sidebar-tree-item
        v-for="child in node.children"
        :key="child.index"
        :node="child"
        :lock="lock"
      />
    </ul>
  </li>
</template>

<script>
// TODO: handle Group with no question
import { mapMutations, mapState } from "vuex";
import api from "@/api";

export default {
  name: "sidebar-tree-item",
  data() {
    return {};
  },
  props: {
    node: Object,
    lock: Boolean,
  },
  computed: {
    ...mapState(["instruction"]),
    title() {
      if (this.node.opt) {
        return (
          api.group_title(this.node, false, {
            stat: `(${this.stats.submitted} / ${this.stats.count})`,
          }) || this.node.index + 1
        );
      } else return this.node.title || this.node.index + 1;
    },
    expand() {
      return this.node.expand & 1;
    },
    clickable() {
      if (this.lock) return false;
      if (this.node.children) return true;
      if (this.node.type == "group") return false;
      if (!this.available) return false;
      if (this.node.children) return true;
      if (this.node.entry == this.$route.params.entry && !this.instruction)
        return false;
      return true;
    },
    marker_class() {
      if (
        this.timeinfo[this.node.entry] &&
        this.timeinfo[this.node.entry].start !== null
      ) {
        if (this.timeinfo[this.node.entry].submit !== null) return ["green"];
        else return ["red"];
      } else return [];
    },
    stats() {
      return api.count_question(this.node);
    },
    available() {
      return api.node_available(this.node);
    },
    group_marker_class() {
      if (this.stats.started)
        if (this.stats.submitted == this.stats.count) return ["green"];
        else if (this.stats.submitted) return ["yellow"];
        else return ["red"];
      else return [];
    },
    ...mapState(["timeinfo", "instruction"]),
  },
  methods: {
    ...mapMutations([
      "toggle_node_expand",
      "toggle_marker",
      "show_instruction",
    ]),
    click_instruction() {
      if (this.instruction && this.instruction.entry == this.node.entry) {
        this.show_instruction(null);
      } else this.show_instruction(this.node);
    },
  },
};
</script>

<style>
.tree-list {
  list-style: none;
  padding-left: 0.8em;
  text-indent: -0.4em;
  margin: 0;
  user-select: none;
  line-height: 1.3;
}
.tree-item-label {
  transition: background 0.1s;
  display: block;
  color: #fff;
  text-decoration: none;
  cursor: default;
}
.tree-item-label.clickable {
  cursor: pointer;
}
.tree-item-label.clickable:hover {
  background-color: rgb(51, 51, 51);
}
.tree-item-label.clickable:focus,
.tree-item-label.clickable:active {
  background-color: rgb(63, 63, 63);
}
.tree-item-label.selected {
  background-color: rgb(73, 73, 73);
}
.tree-item-label.instruction.selected {
  background-color: rgb(102, 64, 64);
}
.tree-item-label.instruction.clickable:hover {
  background: rgb(116, 64, 64);
}
.tree-item-label.instruction.clickable:focus,
.tree-item-label.instruction.clickable:active {
  background: rgb(131, 63, 63);
}
.tree-item-label > span:first-child {
  display: inline-block;
  text-align: center;
  width: 0.4em;
}
.tree-item-label.disabled {
  color: #888;
}
.tree-item-label .right {
  float: right;
  display: block;
  margin-right: 0.5rem;
}
.tree-item-label:after {
  content: "";
  clear: both;
  display: table;
}
.tree-item-label .marker {
  display: inline-block;
  width: 0.9em;
  height: 0.9em;
  border-radius: 0.15em;
  transition: background-color 0.1s;
  background-color: rgb(255, 255, 255, 0.1);
}
.tree-item-label .marker.red {
  background-color: rgb(255, 83, 83);
}
.tree-item-label .marker.green {
  background-color: rgb(101, 255, 114);
}
.tree-item-label .marker.yellow {
  background-color: rgb(252, 255, 101);
}
.tree-item-label.disabled .marker.red {
  background-color: rgb(255, 83, 83, 0.8);
}
.tree-item-label.disabled .marker.green {
  background-color: rgb(101, 255, 114, 0.8);
}
.tree-item-label.disabled .marker.yellow {
  background-color: rgb(252, 255, 101, 0.8);
}
</style>
