<template>
  <main id="login" class="ppform">
    <template v-if="browser_check">
      <div class="fullscreen">
        <button class="btn-fullscreen" type="button" @click="toggleFullscreen">
          <i class="fas fa-expand-arrows-alt"></i>
        </button>
      </div>
      <h1>
        Login
      </h1>
      <form class="login-box" action="" @submit.prevent="try_login">
        <div v-if="error_message" class="error">{{ error_message }}</div>
        <div class="field">
          <label>
            Token
          </label>
          <input :type="visible ? 'text' : 'password'" v-model="token" />
          <button
            class="btn-password"
            type="button"
            @click="visible = !visible"
          >
            <i v-if="visible" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </button>
        </div>
        <div class="field">
          <button type="submit" class="btn-submit">
            Login
          </button>
        </div>
      </form>
    </template>
    <template v-else>
      <h2>
        Unsupported browser.<br />
        Please use lastest Google Chrome or Firefox instead.
      </h2>
    </template>
  </main>
</template>

<script>
import "@/assets/forms.css";
import "@fortawesome/fontawesome-free/css/all.css";
import api from "@/api";
import { mapState, mapMutations } from "vuex";

function check_webp(callback) {
  var img = new Image();
  img.onload = function() {
    var result = img.width > 0 && img.height > 0;
    if (!result) callback();
  };
  img.onerror = function() {
    callback();
  };
  img.src =
    "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
}

export default {
  name: "login",
  data() {
    return {
      error_message: "",
      token: "",
      visible: false,
      browser_check: true,
    };
  },
  props: {},
  computed: {
    ...mapState(["user_info"]),
  },
  methods: {
    ...mapMutations(["exam_reset"]),
    async try_login() {
      try {
        await api.login(this.token);
      } catch (error) {
        this.error_message = error;
      }
    },
    toggleFullscreen() {
      const e = document.body;
      const isFullscreen =
        document.webkitIsFullScreen || document.mozFullScreen || false;
      e.requestFullScreen =
        e.requestFullScreen ||
        e.webkitRequestFullScreen ||
        e.mozRequestFullScreen ||
        (() => false);
      document.cancelFullScreen =
        document.cancelFullScreen ||
        document.webkitCancelFullScreen ||
        document.mozCancelFullScreen ||
        (() => false);

      if (isFullscreen) document.cancelFullScreen();
      else e.requestFullScreen();
    },
  },
  mounted() {
    this.exam_reset();
    check_webp(() => {
      this.browser_check = false;
    });

    (async () => {
      await api.load_user_info(false, true);
      if (this.user_info.success) this.$router.push({ name: "exam" });
    })();
  },
};
</script>

<style>
#login {
  margin: 3em auto 0;
  text-align: center;
  width: 350px;
}
#login h1 {
  font-weight: normal;
}
.login-box {
  padding: 1em;
  border: 1px solid rgb(207, 207, 207);
  box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  background-color: #fff;
}
.error {
  padding: 0.3em;
  border: 1px solid rgb(224, 129, 129);
  border-radius: 0.25em;
  color: rgb(219, 74, 74);
  background-color: rgb(253, 238, 238);
  margin-bottom: 1em;
}
.fullscreen {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
}
</style>
