<template>
  <div class="question-view">
    <div class="question-loading" v-if="!ready">Loading... {{ percent }}</div>
    <div
      class="question-text"
      v-if="question.text"
      v-show="ready"
      v-html="question.text"
    ></div>
    <div
      ref="image-view"
      :style="{
        visibility: ready ? 'visible' : 'hidden',
        filter: `brightness(${brightness}) contrast(${contrast})`,
      }"
    ></div>
    <div class="bar" v-if="src.length >= 1" v-show="ready">
      <div class="btn-group">
        <button
          v-for="(i, k) in src"
          :key="k"
          :style="{ 'background-image': `url(${i})` }"
          @click="select(k)"
          :class="{ active: selected == k }"
        ></button>
      </div>
      <div class="filter-reset ppform">
        <button @click="reset_filter()">Reset</button>
      </div>
      <div class="filter">
        B
        <input
          type="range"
          v-model="brightness"
          min="0.2"
          max="4"
          step="0.01"
        /><br />
        C
        <input type="range" v-model="contrast" min="0.2" max="4" step="0.01" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageViewer from "iv-viewer";
import "iv-viewer/dist/iv-viewer.css";
import axios from "axios";

export default {
  name: "question-view",
  data() {
    return {
      selected: 0,
      viewer: null,
      loading: true,
      progress: [],
      contrast: 1,
      brightness: 1,
    };
  },
  props: {
    question: Object,
    lock: Boolean,
  },
  computed: {
    src() {
      if (!this.question.images) return [];
      return this.question.images.map((i) => "/api/res/" + i);
    },
    ready() {
      return !this.loading && !this.lock;
    },
    percent() {
      if (!this.progress.length) return "";
      let sum_total = 0;
      let sum_loaded = 0;
      for (const [loaded, total] of this.progress) {
        sum_total += total;
        sum_loaded += loaded;
      }
      if (sum_total == 0) return "";
      return Math.floor((sum_loaded / sum_total) * 100) + "%";
    },
  },
  mounted() {
    if (this.question.images) {
      let load_count = this.src.length;
      const onload = () => {
        if (--load_count == 0) {
          this.$emit("load");
          this.viewer = new ImageViewer(this.$refs["image-view"]);
          this.loading = false;
          this.select(0);
          this.$nextTick(() => {
            window.dispatchEvent(new Event("resize"));
          });
        }
      };
      this.progress = [];
      for (const i of this.src) {
        let img = new Image();
        img.src = i;
        img.onload = onload;

        const progress_index = this.progress.length;
        this.progress.push([0, 0]);
        axios.get(i, {
          onDownloadProgress: (progressEvent) => {
            this.$set(this.progress, progress_index, [
              progressEvent.loaded,
              progressEvent.total,
            ]);
          },
        });

        let reload_count = 0;
        img.onerror = () => {
          img.src = i + "?reload=" + ++reload_count;
        };
      }
    } else {
      this.$emit("load");
      this.loading = false;
    }
  },
  methods: {
    reset_filter() {
      this.contrast = 1;
      this.brightness = 1;
    },
    select(k) {
      this.selected = k;
      this.viewer.load(this.src[k]);
    },
  },
};
</script>

<style>
.question-view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.question-view .question-text,
.question-view .question-loading {
  margin: 1rem;
  padding: 0 1em;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  flex: 0 1 auto;
}
.question-view .question-loading {
  padding: 0.5em 1em;
  text-align: center;
  font-size: 2rem;
}

.iv-container {
  flex: 1 1 auto;
}
.question-view .bar {
  background-color: #ddd;
  padding: 0.25rem 0;
  text-align: center;
  flex: 0 1 auto;
  display: flex;
}
.question-view .btn-group {
  flex: 1 1 auto;
  font-size: 0;
}
.question-view .bar .filter-reset,
.question-view .bar .filter {
  flex: 0 1 auto;
  background-color: #c3c3c3;
  font-size: 0.8rem;
  padding: 0 0.5rem;
}
.question-view .bar .filter-reset {
  border-left: 1px solid #777;
  padding-right: 0;
}
.question-view .bar .filter input {
  vertical-align: middle;
  height: 1rem;
  width: 10rem;
}
.question-view .bar .filter-reset button {
  width: 3rem;
  height: 2.5rem;
  margin-top: 0.25em;
  padding: 0;
}
.question-view .bar .btn-group button {
  width: 3rem;
  height: 3rem;
  border: 1px #999 solid;
  background-color: #bbb;
  border-radius: 0.3rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-left: 0.25em;
  outline: 0;
  transition: background-color 0.1s, border-color 0.1s;
}
.question-view .bar .btn-group button:first-child {
  margin-left: 0;
}
.question-view .bar .btn-group button:hover {
  border: 1px #888 solid;
  background-color: #aaa;
}
.question-view .bar .btn-group button:active,
.question-view .bar .btn-group button:focus {
  border: 1px #777 solid;
  background-color: #999;
}
.question-view .bar .btn-group button.active {
  border: 1px #333 solid;
  background-color: #999;
}

/* Dark mode */

#exam.dark .question-view .bar {
  background-color: #191919;
}
#exam.dark .question-view .bar .filter-reset,
#exam.dark .question-view .bar .filter {
  background-color: inherit;
}
#exam.dark .question-view .bar .filter-reset {
  border-left: 1px solid #555;
}
#exam.dark .question-view .bar .filter-reset button {
  border: 1px #333 solid;
  background-color: #333;
  color: #fff;
}
#exam.dark .question-view .bar .filter-reset button:hover,
#exam.dark .question-view .bar .filter-reset button:focus {
  background-color: #444;
}
#exam.dark .question-view .bar .filter-reset button:active {
  background-color: #555;
}
#exam.dark .question-view .bar .btn-group button {
  border: 1.6px #000 solid;
  background-color: #000;
}
#exam.dark .question-view .bar .btn-group button:hover,
#exam.dark .question-view .bar .btn-group button:focus {
  border: 1.6px #555 solid;
  background-color: #000;
}
#exam.dark .question-view .bar .btn-group button:active {
  border: 1.6px #888 solid;
  background-color: #000;
}
#exam.dark .question-view .bar .btn-group button.active {
  border: 1.6px rgb(201, 201, 0) solid;
  background-color: #000;
}
#exam.dark .question-view .question-text,
#exam.dark .question-view .question-loading {
  border: 1px solid #888;
}
</style>
