import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tree: {
      init: true,
      type: "group",
      opt: {
        title: "Loading...",
      },
      children: [],
      // map
      entry_map: {},
      entryi_map: {},
      node_path: {},
      nextevent: Infinity,
    },
    questions: {},
    user_info: {
      success: false,
      name: "",
      aux: "",
      aux2: "",
    },
    timeinfo: {},
    timeoffset: 0,
    tree_time: 0,
    instruction: null,
  },
  mutations: {
    exam_reset(state) {
      state.tree = {
        init: true,
        type: "group",
        opt: {
          title: "Loading...",
        },
        children: [],
      };
      state.questions = {};
      state.user_info = {
        success: false,
        name: "",
        aux: "",
      };
      state.timeinfo = {};
    },
    update_tree(state, tree) {
      state.tree = api.hook_build_tree(tree);
    },
    update_tree_time(state, tree_time) {
      state.tree_time = tree_time;
    },
    update_timeevent(state, timeevent) {
      state.timeevent = timeevent;
    },
    update_user_info(state, user_info) {
      state.user_info = user_info;
    },
    update_questions(state, question) {
      Vue.set(state.questions, question.entry, question);
    },
    show_instruction(state, node) {
      if (node)
        state.instruction = {
          entry: node.entry,
          data: node.opt.instruction,
        };
      else state.instruction = null;
    },
    update_answer(state, { entry, answer }) {
      Vue.set(state.questions[entry], "answer", answer);
    },
    update_timeinfo(state, timeinfos) {
      for (const entry in timeinfos) {
        Vue.set(state.timeinfo, entry, timeinfos[entry]);
      }
      api.hook_timeinfo_after(timeinfos);
    },
    set_timeoffset(state, timeoffset) {
      state.timeoffset = timeoffset;
    },
    toggle_node_expand(state, node) {
      node.expand = 2 | !(node.expand & 1);
    },
    expand_here(state, { entry, expand }) {
      let { node, path } = api.get_node(entry);
      while (node) {
        if (expand) {
          // force expand
          if (!(node.expand & 1)) {
            node.expand = true;
          }
        } else {
          // close if not user-expanded
          if (!(node.expand & 2)) {
            node.expand = false;
          }
        }
        node = path.pop();
      }
    },
  },
});
